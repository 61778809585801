import React from 'react'
import './grtwork.scss'

import CaptureImage from './mainImage'
import img from '../../assets/img/Email Signature Template .jpg'

const great = () => {
  return (
    <div className="great-work-section service-product-bg">
      <div className="container">
        <div className="main-field justify-content-center">
          <div className="first-child">
            <h3 className="">
              <strong>WE ARE</strong>
            </h3>
            <h1 className="pb_15">
              <strong>GREAT PLACE TO WORK - CERTIFIED&trade; </strong>
            </h1>
            <h6 className="pb_15">
              Building and sustaining High-Trust, High-Performance
              Culture&trade;{' '}
            </h6>
          </div>
          <div className="image-section">
            <CaptureImage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default great
